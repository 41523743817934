<!-- @format -->

<template>
  <div>
    <div v-if="loader" class="bg-white animate-pulse">
      <div class="flex mt-8 justify-between mr-5">
        <div class="p-5">
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
          ></div>
        </div>
        <div class="p-5">
          <div
            class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
          ></div>
        </div>
      </div>
      <div
        role="status"
        class="space-y-8 animate-pulse md:space-y-0 md:space-x-8 md:flex md:items-center px-4 py-4 border bg-white"
        v-for="key in 7"
        :key="key"
      >
        <div
          class="flex items-center justify-center bg-gray-300 rounded dark:bg-gray-500"
        >
          <svg
            class="w-10 h-10 text-gray-200 dark:text-gray-600"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            viewBox="0 0 20 18"
          >
            <path
              d="M18 0H2a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Zm-5.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm4.376 10.481A1 1 0 0 1 16 15H4a1 1 0 0 1-.895-1.447l3.5-7A1 1 0 0 1 7.468 6a.965.965 0 0 1 .9.5l2.775 4.757 1.546-1.887a1 1 0 0 1 1.618.1l2.541 4a1 1 0 0 1 .028 1.011Z"
            />
          </svg>
        </div>
        <div class="flex items-center justify-between w-full">
          <div>
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-24 mb-2.5"
            ></div>

            <div
              class="h-2 bg-gray-200 rounded-full dark:bg-gray-500 w-80"
            ></div>
          </div>
          <div class="pl-52 pb-3">
            <div
              class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-500 w-12 my-2"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="bg-white rounded-md">
      <div class="flex justify-between items-center p-5">
        <div class="align-middle font-base text-lg">
          Archived
          {{ complianceType }}
        </div>
        <v-select
          class="w-72"
          label="status"
          @update:modelValue="filteredData"
          v-model="selectedFilter"
          placeholder="Show By"
          :options="
            complianceType?.toLowerCase()?.includes('policies')
              ? archivedStatusOptionForPolicies
              : archivedStatusOptions
          "
        >
        </v-select>
      </div>
      <div
        class="py-4 px-6 flex w-full gap-4 border-t cursor-pointer items-center"
        v-for="data in archivedCompliance?.data"
        @click="navigate(data?.id)"
        :key="data"
      >
        <div
          class="flex w-1/2 items-center"
          :class="opactiyForStatus(data?.status)"
        >
          <div
            class="w-12 flex"
            :class="data?.status === 3 ? 'gray-scale' : ''"
          >
            <img
              class="w-full"
              src="../../../../assets/icons/compliance_File.svg"
            />
          </div>
          <div class="w-full px-2 pt-2">
            <div class="text-md line-clamp-2 capitalize line-clamp">
              {{ data?.name }}
            </div>
            <div class="flex gap-3 align-center">
              <div
                class="flex gap-2 font-light text-sm items-center capitalize"
              >
                <span
                  class="material-icons w-3 text-sm"
                  :class="colorForStatus(data?.status)"
                  >format_list_bulleted_icon</span
                >
                {{ data?.sub_type }}
              </div>
              <div class="flex font-light text-sm items-center">
                <img
                  src="../../../../assets/icons/calendar_clock.svg"
                  :class="data?.status === 3 ? 'gray-scale' : ''"
                  class="w-5 pr-1"
                />
                {{
                  moment(
                    data?.due_date ? data?.due_date : data?.end_date
                  ).format("MMM DD, YYYY")
                }}
              </div>
              <div
                class="flex gap-1 font-light text-sm items-center"
                v-if="data?.status !== 3"
              >
                <img
                  v-if="data?.status === 2"
                  class="w-4"
                  src="../../../../assets/icons/discarded_calender_clock.svg"
                />
                <span
                  v-else
                  class="material-icons text-lg w-4"
                  :class="dateColorForStatus(data?.status)"
                  >event_available_outlined</span
                >
                {{ moment(data?.updated_at).format("MMM DD, YYYY") }}
              </div>
              <div class="flex gap-2 font-light text-sm items-center">
                <span
                  class="material-icons text-lg w-4"
                  :class="colorForStatus(data?.status)"
                  >update_icon</span
                >
                {{
                  data?.is_recurring
                    ? occurenceFormat(data?.occurrence)
                    : "Once"
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-2/3 flex items-center justify-end">
          <div
            class="status align-middle flex justify-center items-center w-max rounded-lg h-6 p-2"
            :class="
              data?.status === 2
                ? 'bg-red-50 text-red'
                : data?.status === 1 || data?.status === 4
                ? 'bg-green-50 text-green'
                : data?.status === 3
                ? 'bg-black text-black bg-opacity-10'
                : ''
            "
          >
            <span
              class="capitalize text-sm"
              :class="
                data?.status === 2
                  ? ' text-red'
                  : data?.status === 1 || data?.status === 4
                  ? ' text-green'
                  : data?.status === 3
                  ? ' text-black bg-opacity-10'
                  : ''
              "
              >{{ archivedStatus(data?.status) }}</span
            >
          </div>
        </div>
      </div>
      <Pagination
        :currentPage="archivedCompliance?.current_page"
        :totalPages="archivedCompliance?.last_page"
        @page-changed="atPageChange"
      />
    </div>
  </div>
</template>

<script setup>
import Pagination from "@/components/newUi/atoms/Pagination.vue";
import { computed, onMounted, ref } from "vue";
import moment from "moment";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const complianceType = ref(route.params.complianceType);
const loader = ref(false);
const selectedFilter = ref();

const store = useStore();

const archivedStatusOptions = ref([
  {
    id: 1,
    status: "Completed",
  },
  {
    id: 2,
    status: "Discarded",
  },
  {
    id: 3,
    status: "Expired",
  },
]);

const archivedStatusOptionForPolicies = ref([
  {
    id: 4,
    status: "Reviewed",
  },
  {
    id: 2,
    status: "Discarded",
  },
  {
    id: 3,
    status: "Expired",
  },
]);

const navigate = (complianceId) => {
  router.push({
    name: "hq-monitoringOfPracticeFunctionsSingleComplianceDetails",
    params: { complianceId: complianceId, isArchived: 1 },
  });
};
const occurenceFormat = (occurrence) => {
  switch (occurrence) {
    case 1:
      return "One Month";
    case 2:
      return "Two Months";
    case 3:
      return "Three Months";
    case 4:
      return "Four Months";
    case 5:
      return "Five Months";
    case 6:
      return "Six Months";
    case 7:
      return "Seven Months";
    case 8:
      return "Eight Months";
    case 9:
      return "Nine Months";
    case 10:
      return "Ten Months";
    case 11:
      return "Eleven Months";
    case 12:
      return "Twelve Months";
  }
};

const opactiyForStatus = (status) => {
  switch (status) {
    case 1:
      return "opacity-100"; // Fully opaque
    case 2:
      return "opacity-50"; // Semi-transparent
    case 3:
      return "opacity-50"; // Mostly transparent
    default:
      return "opacity-100"; // Default to fully opaque if status is unknown
  }
};
const colorForStatus = (status) => {
  switch (status) {
    case 3:
      return "text-black"; // Mostly transparent
    default:
      return "text-teal"; // Default to fully opaque if status is unknown
  }
};

const dateColorForStatus = (status) => {
  switch (status) {
    case 3:
      return "text-black";
    case 2:
      return "text-red";
    default:
      return "text-teal";
  }
};

const archivedStatus = (status) => {
  switch (status) {
    case 1:
      return "Completed";
    case 2:
      return "Discarded";
    case 3:
      return "Expired";
    case 4:
      return "Reviewed";
    default:
      return "unknown";
  }
};

const atPageChange = (page) => {
  if (page > archivedCompliance.value?.last_page) {
    return;
  } else {
    fetchArchived(page);
  }
};

const filteredData = async (status) => {
  selectedFilter.value = status;
  if (status) {
    loader.value = true;
    await store
      .dispatch("hqComplianceCalender/fetchSingleComplianceTypeArchive", {
        complianceType: complianceType.value,
        status: status.id,
      })
      .then(() => {
        loader.value = false;
      });
  } else {
    fetchArchived();
  }
};

const archivedCompliance = computed(() => {
  return store.getters["hqComplianceCalender/getSingleComplianceTypeArchives"];
});

const fetchArchived = async (page) => {
  loader.value = true;
  await store
    .dispatch("hqComplianceCalender/fetchSingleComplianceTypeArchive", {
      complianceType: complianceType.value,
      page: page,
    })
    .then(() => {
      loader.value = false;
    });
};

onMounted(() => {
  fetchArchived(1);
});
</script>

<style scoped>
::v-deep .vs__dropdown-toggle {
  padding: 6px;
  background-color: white;
  cursor: pointer !important;
}
::v-deep ul li:not(:hover) {
  background-color: white !important;
  color: black;
}
::v-deep ul li:hover {
  background-color: teal !important;
}

.gray-scale {
  filter: grayscale(1);
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
